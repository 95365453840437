import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessageSnackbarService {

  constructor(public snackBar: MatSnackBar,) { }

  successSnackbar(message, className = 'success-snack-bar') {

    this.snackBar.open(message, 'close', {
      duration: 5000,
      panelClass: [className],
    });
  }
  errorSnackbar(message, className = 'error-snack-bar') {

    this.snackBar.open(message, 'close', {
      duration: 5000,
      panelClass: [className],
    });
  }
}
